import { Swipe, SwipeItem, Tabs, Tab, Tag, Icon, Image, RadioGroup, Radio, Grid, GridItem } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import Login from '../../intercept/Login.vue'
import LogVisit from '../../common/LogVisit.vue'
import Call from '../../common/Call.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        Login: Login,
        LogVisit: LogVisit,
        Call: Call,
        Clue: Clue,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Image.name]: Image,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem
    },
    data () {
        return {
            code: '',
            loadingShow: false,
            shareShow: false,
            active: 'STF',
            applyActive: '',
            business: {},
            product: {},
            detail: {},
            grade: ''
        }
    },
    mounted () {
        var query = this.$route.query
        this.code = query.code
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.$refs.logVisit.createLog()
            this.retrieveProduct()
            this.$refs.share.default('main')
        },
        async retrieveProduct () {
            this.loadingShow = true
            var that = this
            var pd = { code: that.code }
            const { data: res } = await this.$http.post(this.BMS_URL + '/study/productAPC/retrieveProduct', this.$qs.stringify(pd))
            this.loadingShow = false
            if (res.status === '200') {
                this.product = res.data
                this.retrieveUniversityDetail()
                this.createViewClue()
            }
        },
        async retrieveUniversityDetail () {
            var pd = { universityCode: this.product.universityCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/oversea/universityAPC/retrieveUniversityDetail', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.detail = res.data
            }
        },
        async createOrder () {
            this.loadingShow = true
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
            var pd = {
                productCode: this.code,
                userCode: user,
                operatorCode: operator,
                sellerCode: seller,
                refereeCode: referee
            }
            const { data: res } = await this.$http.post(this.BMS_URL + '/study/order/createOrder', this.$qs.stringify(pd))
            this.loadingShow = false
            if (res.status === '200') {
                this.createOrderClue(res.data.code)
                this.$dialog.alert({
                    title: '咨询申请',
                    message: '咨询申请成功,请耐心等待或到店咨询'
                }).then(() => {
                    this.back()
                })
            } else {
                this.$dialog.alert({
                    title: '申请失败',
                    message: res.msg
                })
            }
        },
        shareImage () {
            this.$router.push({ path: '/mde/recruit/shareImage', query: { taskCode: this.taskCode } })
        },
        onlineChat () {
            this.$refs.call.callWinShow('true')
        },
        createViewClue () {
            var param = { productCode: this.code, url: window.location.href }
            var content = '出国留学浏览'
            this.$refs.clue.createClue('STUDY_VIEW_SCHOOL', this.code, JSON.stringify(param), content)
        },
        createOrderClue (orderCode) {
            var param = { productCode: this.code, orderCode: orderCode, url: window.location.href }
            var content = '出国留学订单'
            this.$refs.clue.createClue('STUDY_ORDER_SCHOOL', orderCode, JSON.stringify(param), content)
        },
        back () {
            var backurl = window.sessionStorage.getItem(window.const.global.STUDY_DETAIL_INDEX_BACK)
            if (backurl !== undefined && backurl !== null) {
                window.location.href = backurl
            } else {
                this.$router.push({ path: '/mde/visa/home' })
            }
        }
    }
}
