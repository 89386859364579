<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <Navbar title="留学明细" :callback="back" type="SMP" />
    <div class="simple">
      <van-row>
        <van-col span="8">
          <van-image radius="3" :src="product.imagePath"></van-image>
        </van-col>
        <van-col span="16">
          <van-row class="item">
            <van-col span="24" class="name text-short">
              {{ product.name }}
            </van-col>
          </van-row>
          <van-row class="item bbd">
            <van-col span="24" class="name-en text-short">
              {{ product.nameEn }}
            </van-col>
          </van-row>
          <van-row class="item bbd">
            <van-col span="8"
              ><van-tag mark :color="COLOR_M">国家：</van-tag></van-col
            >
            <van-col span="16">
              {{ product.countryName }}
            </van-col>
          </van-row>
          <van-row class="item bbd">
            <van-col span="8"
              ><van-tag mark :color="COLOR_M">城市：</van-tag></van-col
            >
            <van-col span="16">
              {{ product.city }}
            </van-col>
          </van-row>
          <van-row class="item bbd">
            <van-col span="8"
              ><van-tag mark :color="COLOR_M">性质：</van-tag></van-col
            >
            <van-col span="16">
              <span v-if="product.quality === 'GOV'">公立</span
              ><span v-if="product.quality === 'PSN'">私立</span>
            </van-col>
          </van-row>
          <van-row class="item">
            <van-col span="8"
              ><van-tag mark :color="COLOR_M">类型：</van-tag></van-col
            >
            <van-col span="16">
              <span v-if="product.type === 'CHS'">综合</span>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
      <div class="hr"></div>
      <van-row class="service">
        <van-col span="3"
          ><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-service"></use></svg
        ></van-col>
        <van-col span="15" class="text"
          ><span class="color-s2">专业服务团队，线下1V1服务</span></van-col
        >
        <van-col span="6" class="button">
          <van-button
            class="btn"
            icon="gold-coin-o"
            :color="COLOR_M"
            size="mini"
            @click="createOrder()"
            >咨询申请</van-button
          >
        </van-col>
      </van-row>
      <div class="hr"></div>
      <van-row class="service">
        <van-col span="3"
          ><svg class="icon02" aria-hidden="true">
            <use xlink:href="#icon-talk"></use></svg
        ></van-col>
        <van-col span="15" class="text"
          ><span class="color-s2">线上客服免费咨询</span></van-col
        >
        <van-col span="6" class="button">
          <van-button
            class="btn"
            icon="chat-o"
            :color="COLOR_S1"
            size="mini"
            @click="onlineChat()"
            >在线咨询</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-row @click="home()">
      <van-col span="24">
        <van-image
          class="focus-img"
          :src="require('../../../../assets/images/recruit/wp-focus.jpg')"
        ></van-image>
      </van-col>
    </van-row>
    <div class="detail">
      <van-tabs :active="active" :color="COLOR_M" border="true">
        <van-tab title="学院详情">
          <van-row>
            <van-col
              span="8"
              class="item brs bbs"
              v-if="detail.rankUsnews !== undefined && detail.rankUsnews !== ''"
            >
              <div class="number color-s1">第{{ detail.rankUsnews }}名</div>
              <div class="text">USNEWS排名</div>
            </van-col>
            <van-col
              span="8"
              class="item brs bbs"
              v-if="detail.rankThames !== undefined && detail.rankThames !== ''"
            >
              <div class="number color-s1">第{{ detail.rankThames }}名</div>
              <div class="text">泰晤士排名</div>
            </van-col>
            <van-col
              span="8"
              class="item bbs"
              v-if="
                detail.ratioStudent !== undefined && detail.ratioStudent !== ''
              "
            >
              <div class="number color-s1">{{ detail.ratioStudent }}</div>
              <div class="text">国际学生比例</div>
            </van-col>
            <van-col
              span="8"
              class="item brs bbs"
              v-if="
                detail.ratioTeacher !== undefined && detail.ratioTeacher !== ''
              "
            >
              <div class="number color-s1">{{ detail.ratioTeacher }}</div>
              <div class="text">师生比例</div>
            </van-col>
            <van-col
              span="8"
              class="item brs bbs"
              v-if="
                detail.ratioGender !== undefined && detail.ratioGender !== ''
              "
            >
              <div class="number color-s1">{{ detail.ratioGender }}</div>
              <div class="text">男女比例</div>
            </van-col>
            <van-col
              span="8"
              class="item bbs"
              v-if="
                detail.countStudent !== undefined && detail.countStudent !== ''
              "
            >
              <div class="number color-s1">{{ detail.countStudent }}</div>
              <div class="text">学生人数</div>
            </van-col>
            <van-col
              span="8"
              class="item brs bbs"
              v-if="
                detail.countUgraduate !== undefined &&
                detail.countUgraduate !== ''
              "
            >
              <div class="number color-s1">{{ detail.countUgraduate }}</div>
              <div class="text">本科生人数</div>
            </van-col>
            <van-col
              span="8"
              class="item brs bbs"
              v-if="
                detail.countGraduate !== undefined &&
                detail.countGraduate !== ''
              "
            >
              <div class="number color-s1">{{ detail.countGraduate }}</div>
              <div class="text">研究生人数</div>
            </van-col>
            <van-col
              span="8"
              class="item bbs"
              v-if="detail.rateJob !== undefined && detail.rateJob !== ''"
            >
              <div class="number color-s1">{{ detail.rateJob }}</div>
              <div class="text">就业率</div>
            </van-col>
            <van-col
              span="8"
              class="item brs"
              v-if="detail.rateFinish !== undefined && detail.rateFinish !== ''"
            >
              <div class="number color-s1">{{ detail.rateFinish }}</div>
              <div class="text">毕业率</div>
            </van-col>
            <van-col
              span="8"
              class="item brs"
              v-if="detail.salary !== undefined && detail.salary !== ''"
            >
              <div class="number color-s1">{{ detail.salary }}</div>
              <div class="text">毕业薪资</div>
            </van-col>
            <van-col
              span="8"
              class="item"
              v-if="detail.coverArea !== undefined && detail.coverArea !== ''"
            >
              <div class="number color-s1">{{ detail.coverArea }}</div>
              <div class="text">占地面积</div>
            </van-col>
          </van-row>
          <div class="title">院校介绍</div>
          <div class="content" v-html="detail.introduce"></div>
          <div class="title">院校优势</div>
          <div class="content" v-html="detail.advantage"></div>
        </van-tab>
        <!-- <van-tab title="申请攻略">
          <van-tabs :active="applyActive" type="card" class="apply">
            <van-tab title="本科生">内容 1</van-tab>
            <van-tab title="研究生">内容 2</van-tab>
            <van-tab title="艺术生">内容 3</van-tab>
          </van-tabs>
        </van-tab> -->
      </van-tabs>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Call
      ref="call"
      win-show="false"
      entry-show="false"
      clue-model="STUDY_CONSULT_ONLINE"
    />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="RCT" module="RCT_DETAIL" />
    <Loading :show="loadingShow" />
    <Clue ref="clue" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
